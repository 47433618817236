:root {
  --size-2: 2px;
  --size-4: 4px;
  --size-8: 8px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-52: 52px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-100: 100px;

  /* Scaling sizes */
  --size-20-54: clamp(20px, calc(20px + 34 * var(--m)), 54px);
}
