:root {
  --font-family-base: myriad-pro, sans-serif;
  --font-family-alt: minion-pro, serif;
  --font-family-condensed-heading: myriad-pro-condensed, sans-serif;

  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-600: 600;
  --font-weight-700: 700;

  --line-height-heading-group-subtitle: 0.8;
  --line-height-share-modal: 1;
  --line-height-heading: 1;
  --line-height-heading-group-title: 1.1;
  --line-height-subheading: 1.2;
  --line-height-text: 1.45;

  /* Sizes */
  --font-gap: 5px;
  --font-size-unit: 1px;
  --font-size-viewport-min-width: 480;
  --font-size-viewport-max-width: 1920;
  --font-size-viewport-min-height: 667; /* iPhone SE height */
  --font-size-viewport-max-height: 1024; /* Commmon desktop size */
  --font-size-growth-width: calc(1 / (var(--font-size-viewport-max-width) - var(--font-size-viewport-min-width)));
  --font-size-growth-height: calc(1 / (var(--font-size-viewport-max-height) - var(--font-size-viewport-min-height)));

  --m: min(
    /* Width calculation */
    calc((var(--font-size-growth-width) * 100vw) - (var(--font-size-growth-width) * var(--font-size-viewport-min-width) * var(--font-size-unit))),
    /* Height calculation */
    calc((var(--font-size-growth-height) * 100vh) - (var(--font-size-growth-height) * var(--font-size-viewport-min-height) * var(--font-size-unit)))
  );

  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-28: 28px;
  --font-size-34: 34px;
  --font-size-38: 38px;
  --font-size-42: 42px;
  --font-size-55: 55px;
  --font-size-75: 75px;
  --font-size-115: 115px;

  --font-size-12-14: clamp(12px, calc(12px + 2 * var(--m)), 14px);
  --font-size-12-28: clamp(12px, calc(12px + 16 * var(--m)), 28px);
  --font-size-14-16: clamp(14px, calc(14px + 2 * var(--m)), 16px);
  --font-size-14-20: clamp(14px, calc(14px + 6 * var(--m)), 20px);
  --font-size-16-20: clamp(16px, calc(16px + 4 * var(--m)), 20px);
  --font-size-16-18: clamp(16px, calc(16px + 2 * var(--m)), 18px);
  --font-size-18-22: clamp(18px, calc(18px + 4 * var(--m)), 22px);
  --font-size-24-28: clamp(24px, calc(24px + 4 * var(--m)), 28px);
  --font-size-28-32: clamp(28px, calc(28px + 4 * var(--m)), 32px);
  --font-size-25-36: clamp(25px, calc(25px + 11 * var(--m)), 36px);
  --font-size-32-40: clamp(32px, calc(32px + 8 * var(--m)), 40px);
  --font-size-32-46: clamp(32px, calc(32px + 14 * var(--m)), 46px);
  --font-size-36-45: clamp(36px, calc(36px + 9 * var(--m)), 45px);
  --font-size-36-57: clamp(36px, calc(36px + 21 * var(--m)), 57px);
  --font-size-42-65: clamp(45px, calc(45px + 20 * var(--m)), 65px);
  --font-size-52-58: clamp(52px, calc(52px + 6 * var(--m)), 58px);
  --font-size-55-115: clamp(55px, calc(55px + 60 * var(--m)), 115px);
  --font-size-75-115: clamp(75px, calc(75px + 40 * var(--m)), 115px);
}
