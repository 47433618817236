/* TEMP COLORS, these are the basis of Rabobank,
Rabo &CO will have their own, namespaces should be the same for consistency across the clients domain */

:root {
  --color-white: #fff;
  --color-white--10: rgba(255, 255, 255, 0.1);
  --color-white--20: rgba(255, 255, 255, 0.2);
  --color-white--50: rgba(255, 255, 255, 0.5);

  --color-black--10: rgba(0, 0, 0, 0.1);
  --color-black--20: rgba(0, 0, 0, 0.2);
  --color-black--30: rgba(0, 0, 0, 0.3);
  --color-black--40: rgba(0, 0, 0, 0.4);
  --color-black--50: rgba(0, 0, 0, 0.5);
  --color-black--60: rgba(0, 0, 0, 0.6);
  --color-black: #000;

  --color-brown-500--10: rgba(149, 137, 122, 0.1);
  --color-brown-500: #95897a;

  --color-gray-100: #f5f5f5;
  --color-gray-200: #d9d9d9;
  --color-gray-300: #ededee;
  --color-gray-400: #333;
  --color-gray-500: #7d8e98;
  --color-gray-600: #2b2e34;
  --color-gray-900--50: rgba(32, 33, 34, 0.5);
  --color-gray-900: #202122;

  --color-cyan-100--20: rgba(189, 206, 211, 0.2);
  --color-cyan-100--50: rgba(189, 206, 211, 0.5);
  --color-cyan-100: #bdced3;
  --color-cyan-300--50: rgba(229, 243, 248, 0.5);
  --color-cyan-300: #e5f3f8;

  --color-cream-100--50: rgba(235, 224, 211, 0.5);
  --color-cream-100: #ebe0d3;
  --color-cream-200: #f9f1e7;
  --color-cream-400: #f3e9dc;
  --color-cream-500: #a69887;

  --color-green-100--50: rgba(227, 233, 210, 0.5);
  --color-green-100: #e3e9d2;
  --color-green-200: #f3f7e6;
  --color-green-300: #858668;
  --color-green-900: #afcd5b;

  --color-red-100: #ff4545;

  --color-graph-orange: #fa6400;
  --color-graph-blue-dark: #52676d;
}
